<template>
  <!-- Modal utilizando b-modal -->
  <b-modal
    id="webhook-modal"
    v-model="show"
    title="Endereço Webhook"
    header-class="whatsapp-info-header"
    body-bg-variant="whatsapp-info"
    footer-bg-variant="whatsapp-info"
    header-close-variant="white"
    body-class="custom-modal-body"
    title-class="text-white"
    @hide="closeDialog"
    hide-footer
  >
    <!-- Formulário dentro do modal -->
    <div>
      <div class="form-group mt-3">
        <label for="phonePathInput">Campo de telefone:</label>
        <input
          type="text"
          class="form-control"
          v-model="phonePath"
          placeholder="Caminho do número de telefone (Ex: user.phone)"
        />
      </div>

      <div class="form-group mt-3">
        <label for="dataPathInput">Campo de dados:</label>
        <input
          type="text"
          class="form-control"
          v-model="dataPath"
          placeholder="Caminho dos dados (Ex: user.data)"
        />
      </div>

      <!-- Input com o link gerado e botão copiar -->
      <div class="form-group mb-3">
        <label for="linkInput">Link:</label>
        <div class="input-group">
          <input
            type="text"
            id="linkInput"
            class="form-control"
            :value="computedLink"
            readonly
          />
          <div class="input-group-append">
            <button class="btn btn-ok-whatsapp" @click="copyLink">
              <i class="fa-regular fa-copy text-white"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";

export default {
  props: {
    show: Boolean,
    baseLink: String
  },
  data() {
    return {
      phonePath: "",
      dataPath: ""
    };
  },
  computed: {
    // Computa o link atualizado dinamicamente
    computedLink() {
      let updatedLink = this.baseLink;

      if (this.phonePath) {
        updatedLink += `&destinatario=${encodeURIComponent(this.phonePath)}`;
      }
      if (this.dataPath) {
        updatedLink += `&data_path=${encodeURIComponent(this.dataPath)}`;
      }

      return updatedLink;
    }
  },
  methods: {
    copyLink() {
      navigator.clipboard
        .writeText(this.computedLink)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Copiado!",
            text: "O link foi copiado com sucesso.",
            timer: 2000,
            showConfirmButton: false
          });
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Erro!",
            text: "Não foi possível copiar o link."
          });
        });
    },
    closeDialog() {
      this.$emit("close"); // Emite o evento para fechar o modal
    }
  }
};
</script>

<style>
.btn-ok-whatsapp {
  background-color: #462181;
  color: #fff;
}

.custom-modal-body {
  border-bottom-left-radius: 20px; /* Arredonda o canto inferior esquerdo */
  border-bottom-right-radius: 20px; /* Arredonda o canto inferior direito */
}
</style>
