<template>
  <div style="padding: 5px 20px; height: auto">
    <Toast position="top-right" />
    <div class="modal-detalhes-cabecacho m-3">
      <!-- <div @click="$emit('botao-voltar')" class="camp-botao-voltar">
        <b-icon-arrow-left-square font-scale="2.5" style="color: #808080" />
      </div> -->
      <div class="back-arrow-squared" @click="$emit('botao-voltar')">
        <i class="flaticon-up-arrow" style="color: #808080 !important"></i>
      </div>
      <div class="d-flex">
        <button
          class="btn-executar-campanha mr-2"
          variant="primary"
          :class="campanhaExecutada ? 'executed' : ''"
          id="buttonExecutarCampanha"
          @click="lancarCampanha()"
          v-if="isAdmin || hasPermission('executar-campanha')"
        >
          <span>
            <!-- {{ campanhaExecutada ? "Campanha executada" : "Execução manual" }} -->
            {{ btnLancarCampanhaProps.botaoLancarCampanhaTexto }}
          </span>
        </button>
        <b-button
          variant="secondary"
          class="text-uppercase"
          @click="editarCampanha()"
          v-if="isAdmin || hasPermission('gerenciar-campanha')"
          >Editar</b-button
        >
      </div>
    </div>
    <hr style="margin: 2px -20px 10px -20px" />
    <div class="container">
      <div
        class="align-items-center mb-10 mt-5 detalhes-collapse overflow-hidden"
      >
        <div
          class="row pb-5 align-items-center overflow-hidden"
          style="padding: 16px"
        >
          <div class="col-12 col-sm-2 col-xl-1">
            <section style="display: flex; align-items: center">
              <status-components :ativo="this.campanhaAtiva" />
              <!-- <span
              class="modal-detalhes-corpo-secao-informacao ml-2"
              style="display: inline"
              >{{ campanha.mdc_status == "A" ? "Ativa" : "Inativa" }}</span
            > -->
            </section>
          </div>
          <div class="col-12 col-sm-6 col-xl-9 align-items-center">
            <span
              class="modal-detalhes-corpo-secao-descricao"
              v-if="this.campanhaAtiva"
              >{{ campanha.cron_texto || "-" }}</span
            >
            <span class="modal-detalhes-corpo-secao-informacao">
              <strong>{{ campanha.mdc_nome || "-" }}</strong></span
            >
          </div>
          <!-- <div class="col-12 col-sm-6 col-xl-4 align-items-center">
            <span class="modal-detalhes-corpo-secao-descricao">Frequência</span>
            <span class="modal-detalhes-corpo-secao-informacao">
              <strong> {{ campanha.cron_texto || "-" }}</strong></span
            >
          </div> -->
          <div class="col-12 col-sm-4 col-xl-2 d-flex flex-row-reverse">
            <!-- style="display: flex; justify-content: end" -->
            <b-button
              v-b-toggle="'cardCollapse'"
              style="padding: 0; border: none"
            >
              <a
                class="btn btn-icon btn-sm btn-light-primary card-button-collapse"
                style="width: 100px"
                @click="isDetalhesCollapsed = !isDetalhesCollapsed"
                >DETALHES
                <i
                  v-if="isDetalhesCollapsed"
                  class="ki ki-arrow-down icon-nm ml-2"
                  style="color: inherit"
                ></i>
                <i
                  v-if="!isDetalhesCollapsed"
                  class="ki ki-arrow-up icon-nm ml-2"
                  style="color: inherit"
                ></i>
              </a>
            </b-button>
          </div>
        </div>
        <b-collapse
          id="cardCollapse"
          style="border-top: 1px solid #eee; padding: 16px"
        >
          <div class="row">
            <div class="col-12 col-sm-6 col-xl-4 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao"
                >ID da campanha</span
              >
              <span class="modal-detalhes-corpo-secao-informacao">{{
                campanha.modelo_campanha_id || "-"
              }}</span>
            </div>
            <div class="col-12 col-sm-6 col-xl-4 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao d-block"
                >Data de criação</span
              >
              <span class="modal-detalhes-corpo-secao-informacao">{{
                obterDataFormatada(campanha.mdc_data_cadastro) || "-"
              }}</span>
            </div>
            <div class="col-12 col-sm-6 col-xl-4 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao"
                >Ultima atualização</span
              >
              <span class="modal-detalhes-corpo-secao-informacao">
                {{
                  obterDataFormatada(campanha.mdc_data_atualizado) || "-"
                }}</span
              >
            </div>

            <div class="col-12 col-sm-6 col-xl-4 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao">Subconta</span>
              <span class="modal-detalhes-corpo-secao-informacao">{{
                subconta || "-"
              }}</span>
            </div>

            <div class="col-12 col-sm-6 col-xl-4 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao">Canais</span>
              <span class="modal-detalhes-corpo-secao-informacao"
                ><span
                  v-for="(c, index) in canaisDisponiveis"
                  :key="'actives' + index"
                >
                  {{ c.can_nome
                  }}{{ index != canaisDisponiveis.length - 1 ? "," : "" }}</span
                >
                <span
                  v-if="
                    canaisRascunho.length > 0 && canaisDisponiveis.length > 0
                  "
                >
                  &nbsp;-&nbsp;
                </span>
                <span
                  v-for="(c, index) in canaisRascunho"
                  :key="'inactives-' + index"
                  style="white-space: nowrap"
                >
                  <i class="fa-solid fa-pencil pr-2" style="color: #000"></i
                  >{{ c.can_nome
                  }}{{ index != canaisRascunho.length - 1 ? "," : "" }}</span
                >
              </span>
              <span
                v-if="
                  canaisDisponiveis.length == 0 && canaisRascunho.length == 0
                "
              >
                -
              </span>
            </div>

            <div class="col-12 col-sm-6 col-xl-4 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao">Callback</span>
              <span class="modal-detalhes-corpo-secao-informacao">{{
                callbackDaCampanha || "-"
              }}</span>
            </div>

            <div class="col-12 col-sm-6 col-xl-4 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao">Tipo</span>
              <span class="modal-detalhes-corpo-secao-informacao">{{
                modeloCampanha.mt_titulo || "-"
              }}</span>
            </div>

            <div class="col-12 col-sm-6 col-xl-8 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao">Modelo</span>
              <span class="modal-detalhes-corpo-secao-informacao">
                <span class="limita-caracteres">
                  {{ modeloCampanha.md_descricao || "-" }}
                </span>
              </span>
            </div>

            <div class="col-12 col-sm-6 col-xl-4 modal-detalhes-corpo-secao">
              <span class="modal-detalhes-corpo-secao-descricao">Ordem</span>
              <span class="modal-detalhes-corpo-secao-informacao">
                <!-- {{
                modeloCampanha.mt_titulo || "-"
              }} -->
                {{ ordemCampanhaMensagens }}
              </span>
            </div>
            <div
              class="col-12 col-sm-6 col-xl-8 modal-detalhes-corpo-secao"
              v-if="this.campanha.webhook"
            >
              <span class="modal-detalhes-corpo-secao-descricao">
                Endereço do Webhook
              </span>
              <div class="input-group">
                <input
                  type="text"
                  v-model="this.campanha.webhook.url"
                  readonly
                  class="form-control mr-2"
                />
                <button
                  @click="copiarUrl(campanha.webhook.url)"
                  class="btn btn-primary"
                >
                  Copiar URL
                </button>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>

      <div
        v-if="canaisDisponiveis.length > 0"
        class="row mt-6 pb-2"
        style="margin-bottom: 40px"
      >
        <div class="col">
          <span class="modal-detalhes-corpo-secao-informacao">
            <strong>Canais de envio e texto</strong></span
          >
          <h1></h1>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: last baseline;
              width: 100%;
            "
            class="chat-container"
          >
            <b-nav tabs align="left" style="width: 100%">
              <transition-group
                enter-active-class="animate__animated animate__zoomIn"
                leave-active-class="animate__animated animate__zoomOut"
                style="display: flex"
              >
                <b-nav-item
                  v-for="(canal, index) in canaisDisponiveis"
                  :key="index"
                  :active="mensagens.mensagemSelecionada == canal"
                  @click="trocaAbasTexto(canal)"
                  >{{ canal.can_nome.toUpperCase() }}</b-nav-item
                >
              </transition-group>
            </b-nav>
          </div>
          <div class="modal-html-container">
            <div
              style="white-space: pre-line; font-size: 14px"
              v-html="replaceMensagensDeWhatsapp"
              class="camp-body-right-textarea modal-detalhes-corpo-secao-informacao h-75"
            ></div>
          </div>

          <!-- <textarea
              class="camp-body-right-textarea h-75"
              v-model="mensagens.mensagemSelecionada.cac_mensagem"
              type="text"
              :readonly="true"
            />
            <h1>Counter: {{ counter }}</h1>
            <button @click="addToCounter()">+1</button> -->
        </div>
      </div>

      <div
        v-if="historico.length > 0"
        class="align-items-center mb-10 mt-5 detalhes-collapse overflow-hidden"
      >
        <div
          class="row pb-5 align-items-center overflow-hidden d-flex justify-content-between"
          v-b-toggle="'cardCollapseRegister'"
          @click="isRegisterCollapsed = !isRegisterCollapsed"
          style="padding: 16px"
        >
          <div class="col-12 col-sm-6 col-xl-9 align-items-center">
            <span class="modal-detalhes-corpo-secao-informacao">
              <strong>Histórico de execução</strong></span
            >
          </div>
          <div class="col-12 col-sm-4 col-xl-2 d-flex flex-row-reverse">
            <!-- style="display: flex; justify-content: end" -->
            <a>
              <i
                v-if="isRegisterCollapsed"
                class="ki ki-arrow-down icon-nm ml-2"
                style="color: black"
              ></i>
              <i
                v-else
                class="ki ki-arrow-up icon-nm ml-2"
                style="color: black"
              ></i>
            </a>
          </div>
        </div>
        <b-collapse
          id="cardCollapseRegister"
          visible
          style="border-top: 1px solid #eee"
        >
          <div class="col">
            <b-table
              id="tabela-historico"
              :fields="fields"
              :items="historico"
              responsive="sm"
              striped
              hover
              :per-page="porPagina"
              :current-page="paginaAtual"
              :key="componentKey"
            >
              <template #cell(age_data_executado)="data">
                <span style="width: 100px">
                  {{ obterDataFormatada(data.value) }}
                </span>
              </template>

              <template #cell(age_data_finalizado)="data">
                {{ data.value.substring(11, 16) }}
              </template>

              <template #cell(qtd_enviado)="data">
                {{ data.value }}
              </template>
              <template #cell(qtd_enviado_erro)="data">
                {{ data.value }}
              </template>

              <template #cell(status_customizado)="data">
                <b-badge
                  :variant="
                    ['FS'].includes(data.value.age_status)
                      ? 'success'
                      : ['PC'].includes(data.value.age_status)
                      ? 'primary'
                      : ['EC', 'AO', 'TI'].includes(data.value.age_status)
                      ? 'danger'
                      : data.value.age_status == 'FE'
                      ? 'warning'
                      : data.value.age_status == 'TR'
                      ? 'success'
                      : 'danger'
                  "
                  >{{ data.value.texto.toUpperCase() }}</b-badge
                >
              </template>

              <template #cell(show_details)="data">
                <i
                  v-if="data.item.age_status == 'PC'"
                  @click.prevent="cancelarCampanha(data.item)"
                  v-b-tooltip.hover
                  title="Cancelar Campanha"
                  class="mr-2 menu-icon icon-md flaticon2-cancel-music danger"
                  style="cursor: pointer"
                >
                </i>
                <i
                  @click.prevent="abrirRelatorio(data.item)"
                  v-b-tooltip.hover
                  title="Relatório Detalhado"
                  :class="`mr-2 menu-icon icon-md flaticon2-infographic ${
                    ['FS'].includes(data.item.age_status)
                      ? 'success'
                      : ['PC'].includes(data.item.age_status)
                      ? 'primary'
                      : ['EC', 'AO', 'TI'].includes(data.item.age_status)
                      ? 'danger'
                      : data.item.age_status == 'FE'
                      ? 'warning'
                      : data.item.age_status == 'TR'
                      ? 'success'
                      : 'danger'
                  }`"
                  @click="data.toggleDetails"
                  style="cursor: pointer"
                >
                </i>

                <i
                  v-b-tooltip.hover
                  title="Resumo"
                  class="menu-icon icon-md flaticon2-dashboard"
                  @click="(e) => data.toggleDetails()"
                  style="cursor: pointer; color: #ffa800"
                >
                </i>
              </template>

              <template #row-details="row">
                <json-viewer
                  v-if="row.item.age_resumo"
                  :value="JSON.parse(row.item.age_resumo)"
                ></json-viewer>
                <json-viewer v-else value="-"></json-viewer>
              </template>
            </b-table>

            <div
              class="d-flex justify-content-center"
              v-if="historico.length > porPagina"
            >
              <b-pagination
                class="d-flex align-self-center"
                v-model="paginaAtual"
                :total-rows="historico.length"
                :per-page="porPagina"
                aria-controls="tabela-historico"
              ></b-pagination>
            </div>
          </div>
        </b-collapse>
      </div>

      <div
        v-if="filtros.length > 0"
        class="align-items-center mb-10 detalhes-collapse overflow-hidden"
      >
        <div
          class="row pb-5 align-items-center overflow-hidden d-flex justify-content-between"
          v-b-toggle="'cardCollapseFilter'"
          @click="isFilterCollapsed = !isFilterCollapsed"
          style="padding: 16px"
        >
          <div class="col-12 col-sm-6 col-xl-9 align-items-center">
            <span class="modal-detalhes-corpo-secao-informacao">
              <strong>Filtros da campanha</strong></span
            >
          </div>
          <div class="col-12 col-sm-4 col-xl-2 d-flex flex-row-reverse">
            <a>
              <i
                v-if="isFilterCollapsed"
                class="ki ki-arrow-down icon-nm ml-2"
                style="color: black"
              ></i>
              <i
                v-else
                class="ki ki-arrow-up icon-nm ml-2"
                style="color: black"
              ></i>
            </a>
          </div>
        </div>
        <b-collapse id="cardCollapseFilter" style="border-top: 1px solid #eee">
          <nested-draggable
            :group="0"
            :camposPossiveisDoFiltro="camposPossiveisDoFiltro"
            :opreadoresFiltro="opreadoresFiltro"
            :listaDeFiltros="filtros"
            :isDraggable="false"
            :sistemaLicenciadaId="campanha.sistema_licenciada_id"
          >
          </nested-draggable>
        </b-collapse>
      </div>
      <div
        v-if="filtros.length > 0"
        class="texto-literal p-4 border rounded mb-4"
        v-html="tratamentoFiltro(filtros)"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatusComponents from "./StatusComponents.vue";
import ApiService from "@/core/services/api.service";
import API_LINKS from "../../api.links";
import moment from "moment";
import "moment/locale/pt-br";
// import FiltroNested from "./nestedDraggableComponents/FiltroNested.vue";
import nestedDraggable from "../cadastro-componentes/NestedDraggable.vue";
import Toast from "primevue/toast";
import JsonViewer from "vue-json-viewer";
import Swal from "sweetalert2";
import "vue-json-viewer/style.css";
import Utils from "@/core/utils/Utils";

export default {
  components: {
    StatusComponents,
    //  FiltroNested,
    Toast,
    JsonViewer,
    nestedDraggable
  },
  props: ["campanha"],
  data() {
    return {
      ordemCampanhaMensagens: "Simultâneo",
      subcontas: [],
      callbackDaCampanha: "",
      campanhaAtiva: false,
      qtdErroStatus: 0,
      canaisDisponiveis: [],
      canaisRascunho: [],
      cardFiltros: null,
      isDetalhesCollapsed: true,
      isRegisterCollapsed: false,
      isFilterCollapsed: true,
      filterId: 27,
      campanhaExecutada: false,
      modeloId: "",
      tipoCampanha: "",
      modeloCampanha: "",
      opreadoresFiltro: [],
      camposPossiveisDoFiltro: [],
      filtros: [],
      btnLancarCampanhaProps: {
        click: undefined,
        launchStep: 0,
        botaoLancarCampanhaTexto: "Executar campanha"
      },
      counter: 0,
      canais: {
        selecionados: [{ id: 0, nome: "whatsapp" }],
        opcoes: [
          { nome: "whatsapp", id: 0 },
          { nome: "sms", id: 1 },
          { nome: "email", id: 2 },
          { nome: "business intelligence", id: 3 }
        ],
        draggable: {
          ativo: true,
          arrastando: false
        }
      },
      texto: {
        controle: {
          selecionado: "whatsapp",
          variaveis: ["nome", "idade", "nascimento"]
        },
        canaisTexto: {},
        imagem: null
      },
      modelo: {},
      mensagens: {
        lista: {
          whatsapp:
            "Whatsapp msg: Olá @nome, nossa empresa faz anivesário hoje, dia @data_atual. Gostaríamos de convidar você para participar da nossa festa. WHATSAPP",
          sms: "SMS msgs: Olá @nome, nossa empresa faz anivesário hoje, dia @data_atual. Gostaríamos de convidar você para participar da nossa festa. SMS",
          email:
            "Email: Olá @nome, nossa empresa faz anivesário hoje, dia @data_atual. Gostaríamos de convidar você para participar da nossa festa. EMAIL",
          "Painel Executivo: BI": "Mensagem painel executivo BI"
        },
        mensagemSelecionada: {}
      },
      historico: [],
      paginaAtual: 1,
      porPagina: 4,
      filtro: {
        agrupamento: {
          lista: [
            [
              {
                campo: "sexo",
                condicao: "igual",
                valor: "masculino",
                proxLogica: null
              }
            ],
            [
              {
                campo: "idade",
                condicao: "maior que",
                valor: "40",
                proxLogica: "and"
              },
              {
                campo: "idade",
                condicao: "menor que",
                valor: "70",
                proxLogica: null
              }
            ]
          ]
        },
        campos: ["nome", "idade", "sexo"],
        condicoes: [
          { descricao: "maior que", valor: ">" },
          { descricao: "menor que", valor: "<" },
          { descricao: "igual", valor: "==" }
        ],
        codigo: "",
        dragging: false,
        componentData: {
          props: {
            type: "transition",
            name: "flip-list"
          }
        }
      },
      componentKey: 0
    };
  },
  computed: {
    API_LINKS() {
      return API_LINKS;
    },
    ...mapGetters(["isAdmin", "hasPermission"]),
    subconta() {
      const subconta = this.subcontas.find(
        (v) => v.conta_id === this.campanha.mdc_preferencial_conta_id
      );
      return subconta
        ? subconta.con_nome_identificacao || subconta.con_login
        : "";
    },
    replaceMensagensDeWhatsapp() {
      if (this.mensagens.mensagemSelecionada.cac_mensagem) {
        return (
          this.mensagens.mensagemSelecionada.cac_mensagem
            .replace(/~(~*[^~\n]+~*)~/g, "<del>$1</del>")
            .replace(/__/g, "<em>$1</em>")
            .replace(/\*(\**[^*\n]+\**)\*/g, "<strong>$1</strong>")
            .replace(
              /`{3}(`*[^`\n]+`*)`{3}/g,
              '<code class="whatsapp">$1</code>'
            )
            // replace img to img style="max-width: 100%;"
            .replace(/<img/g, '<img style="max-width: 100%;"')
            .replace(/@template\(\S+\)/g, "")
        );
      }
      return this.mensagens.mensagemSelecionada.cac_mensagem;
    },
    filtrosLista() {
      return this.filtro.agrupamento.lista.filter((i) => i.length !== 0);
    },
    canaisExibicao() {
      let filtro = this.canais.selecionados.filter((item) => {
        return item.nome != "business intelligence";
      });
      return filtro;
    },
    fields() {
      const fields = [
        // { key: "index", label: "#", class: "text-left" },
        { key: "age_data_executado", label: "Data", class: "text-left" },
        { key: "age_data_finalizado", label: "Hora", class: "text-left" },
        { key: "qtd_enviado", label: "Enviados", class: "text-center" },
        { key: "qtd_enviado_erro", label: "Erros", class: "text-center" },
        { key: "status_customizado", label: "Status", class: "text-left" },
        { key: "resumo", label: "Resumo", class: "text-center" }
      ];

      if (this.isAdmin || this.hasPermission("visualizar-relatorio")) {
        fields.push({
          key: "show_details",
          label: "Relatório",
          class: "text-center"
        });
      }

      return fields;
    }
  },
  methods: {
    editarCampanha() {
      this.$emit("botao-voltar");
      this.$emit("editar-campanha", this.campanha);
    },
    removeLastMfiEOu(lista) {
      // Último elemento de hierarquia 0
      // lista[lista.length - 1]

      // Último elemento de hierarquia 1
      // lista[lista.length - 1].listaDeFiltros[
      //         lista[lista.length - 1].listaDeFiltros.length - 1
      //       ]

      // Último elemento de hierarquia 2
      // lista[lista.length - 1].listaDeFiltros[
      //   lista[lista.length - 1].listaDeFiltros.length - 1
      // ].listaDeFiltros[
      //   lista[lista.length - 1].listaDeFiltros[
      //     lista[lista.length - 1].listaDeFiltros.length - 1
      //   ].listaDeFiltros.length - 1
      // ]

      // Verifica se a lista tem algum elemento
      if (lista.length > 0) {
        // Verifica se a lista do último elemento da lista de hierarquia 0 tem algum elemento
        if (lista[lista.length - 1].listaDeFiltros.length > 0) {
          // Verifica se o último elemento da lsita de hierarquia 1 tem algum elemento
          if (
            lista[lista.length - 1].listaDeFiltros[
              lista[lista.length - 1].listaDeFiltros.length - 1
            ].listaDeFiltros.length > 0
          ) {
            // console.log(
            //   "última lista de hierarquia 2",
            //   lista[lista.length - 1].listaDeFiltros[
            //     lista[lista.length - 1].listaDeFiltros.length - 1
            //   ].listaDeFiltros[
            //     lista[lista.length - 1].listaDeFiltros[
            //       lista[lista.length - 1].listaDeFiltros.length - 1
            //     ].listaDeFiltros.length - 1
            //   ]
            // );
            const listThree =
              lista[lista.length - 1].listaDeFiltros[
                lista[lista.length - 1].listaDeFiltros.length - 1
              ].listaDeFiltros;
            if (listThree[listThree.length - 1].listaDeFiltros.length > 0) {
              delete listThree[listThree.length - 1].listaDeFiltros[
                listThree[listThree.length - 1].listaDeFiltros.length - 1
              ].mfi_e_ou;
            } else {
              delete lista[lista.length - 1].listaDeFiltros[
                lista[lista.length - 1].listaDeFiltros.length - 1
              ].listaDeFiltros[
                lista[lista.length - 1].listaDeFiltros[
                  lista[lista.length - 1].listaDeFiltros.length - 1
                ].listaDeFiltros.length - 1
              ].mfi_e_ou;
            }
          } else {
            // console.log(
            //   "última lista de hierarquia 1",
            //   lista[lista.length - 1].listaDeFiltros[
            //     lista[lista.length - 1].listaDeFiltros.length - 1
            //   ]
            // );
            delete lista[lista.length - 1].listaDeFiltros[
              lista[lista.length - 1].listaDeFiltros.length - 1
            ].mfi_e_ou;
          }
        } else {
          delete lista[lista.length - 1].mfi_e_ou;
          // console.log(
          //   "última lista de hierarquia 0",
          //   lista[lista.length - 1]
          // );
        }
      }
    },
    lancarCampanha() {
      const btnElement = document.getElementById("buttonExecutarCampanha");

      if (this.btnLancarCampanhaProps.launchStep === 1) {
        this.btnLancarCampanhaProps.botaoLancarCampanhaTexto =
          "campanha executada";
        btnElement.style.background = "#ccc";
        btnElement.style.color = "#666";
        btnElement.disabled = true;
        this.btnLancarCampanhaProps.launchStep++;

        const urlParaDisparar =
          API_LINKS.lancarCampanha + "/" + this.campanha.modelo_campanha_id;
        ApiService.get(urlParaDisparar).then((_) => {
          this.atualizarHistorico();
        });
        this.$toast.add({
          severity: "success",
          summary: "EXECUTADA",
          detail: `Campanha "${this.campanha.mdc_nome}" executada com sucesso!`,
          life: 7000
        });
      } else if (this.btnLancarCampanhaProps.launchStep === 0) {
        btnElement.style.background = "#ffa800";
        btnElement.style.color = "#000";
        this.btnLancarCampanhaProps.botaoLancarCampanhaTexto = "CONFIRMAR";
        this.btnLancarCampanhaProps.launchStep++;
      }
      // ApiService.get();
    },
    copiarUrl(url) {
      // Verifica se o navegador suporta a API de clipboard
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(url)
          .then(() => {
            // Exibe uma mensagem de sucesso (pode ser um alerta ou toast)
            Swal.fire("Copiado!", "O link foi copiado com sucesso.", "success");
          })
          .catch((err) => {
            // Exibe uma mensagem de erro
            Swal.fire("Erro!", "Não foi possível copiar o link.", "error");
          });
      } else {
        // Caso o navegador não suporte a API
        Swal.fire("Erro!", "Não foi possível acessar o link.", "error");
      }
    },
    trocaAbasTexto(canal) {
      this.mensagens.mensagemSelecionada = canal;
    },
    obterDataFormatada(data) {
      return data
        ? moment(data, "YYYY-MM-DD hh:mm:ss")
            .locale("pt-br")
            .format("DD [de] MMMM[,] YYYY")
        : "-";
    },
    obterDataFormatadaHora(data) {
      return data
        ? moment(data, "YYYY-MM-DD hh:mm:ss").locale("pt-br").format("hh:mm")
        : "-";
    },
    // Filter Methods
    adicionarFiltro() {
      this.filtro.agrupamento.lista.push(["novo"]);
    },
    removerComponenteFiltro(idComponente, idGrupo) {
      this.filtro.agrupamento.lista[idGrupo].splice(idComponente, 1);
      this.filtro.agrupamento.lista = this.filtro.agrupamento.lista.filter(
        (i) => i.length !== 0
      );
    },
    abrirRelatorio(execucao) {
      if (
        execucao.age_status == "PC" || // Processando Campanha
        execucao.age_status == "EC" || // Erro Campanha
        execucao.age_status == "AO" // Agente Offline
      ) {
        return;
      }

      const objPeriodo = this.obterPeriodo(
        execucao.age_data_executado,
        execucao.age_data_executado
      );

      this.$router.push({
        name: "relatorio-detalhado",
        params: {
          periodo: objPeriodo,
          modelo: [
            {
              text: this.campanha.mdc_nome,
              value: this.campanha.modelo_campanha_id
            }
          ]
        }
      });
    },
    async cancelarCampanha(execucao) {
      if (execucao.age_status != "PC") {
        Swal.fire({
          title: "Campanha selecionada não se encontra em processamento!",
          showCancelButton: true,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-success"
          },
          buttonsStyling: false
        });
        return;
      }

      Swal.fire({
        title: "Deseja realmente CANCELAR esta campanha?",
        showCancelButton: true,
        confirmButtonText: "Cancelar Campanha",
        cancelButtonText: "Voltar",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-danger"
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          ApiService.get(
            API_LINKS.cancelarCampanhaEmExecucao,
            execucao.agendada_id,
            false
          ).then((res) => {
            Swal.fire({
              title: "Solicitação enviada!",
              showCancelButton: true,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success"
              },
              buttonsStyling: false
            });
          });
        }
      });
    },
    async atualizarHistorico() {
      let responseExecucoes = await ApiService.get(
        API_LINKS.campanhaUltimasExecucoes,
        this.campanha.modelo_campanha_id,
        false
      );
      this.historico = responseExecucoes.data.agendadas.data;
      this.criarStatusCustomizadoHistorico();
    },
    criarStatusCustomizadoHistorico() {
      this.historico.map((item) => {
        item.status_customizado = {
          texto:
            item.age_status == "FS"
              ? "concluído"
              : item.age_status == "FE"
              ? "concluído com erro"
              : item.age_status == "PC"
              ? "processando"
              : item.age_status == "AO"
              ? "agente offline"
              : item.age_status == "TI"
              ? "tempo excedido"
              : item.age_status == "TR"
              ? "reenviada"
              : "erro na campanha",
          age_status: item.age_status
        };
        item.ERROS.map((erro) => {
          erro.lea_requisicao = JSON.parse(erro.lea_requisicao);
        });
      });
    },
    async pegarModeloCampanha(modelo_id) {
      try {
        let responseModelo = await ApiService.get(API_LINKS.modelo, modelo_id);
        let modelo = responseModelo.data.modelo;
        this.modeloCampanha = modelo;
      } catch (E) {
        return;
      }
    },
    tratamentoFiltro(filtros) {
      if (filtros.length == 0) return;
      var texto = "";
      filtros.forEach((filtro) => {
        if (filtro.listaDeFiltros.length == 0) {
          texto += `<strong style="color: #bb3e03">${filtro.fil_titulo
            ?.split("[")[0]
            .replace(";", "")}</strong>  ${
            filtro.ope_titulo?.split("[")[0]
          } <strong style="color: #bb3e03; font-weight: 600">${this.tratamentoComponenteValor(
            filtro
          )}</strong>${
            filtro.mfi_parenteses_fim ?? ""
          }<strong style="color: #264653"; font-weight: 600> ${
            filtro.mfi_e_ou == "AND" ? "E" : filtro.mfi_e_ou == "OR" ? "OU" : ""
          } </strong>`;
        } else {
          texto += `(${this.tratamentoFiltro(filtro.listaDeFiltros)}`;
        }
      });
      return texto;
    },
    tratamentoComponenteValor(filtro) {
      if (filtro.ope_sinal == "IS_NULL") {
        return "";
      }
      if (
        (this.checkStringEmptyNull(filtro.mfi_valor) &&
          this.checkStringEmptyNull(filtro.mfi_valor_2)) ||
        (this.checkStringEmptyNull(filtro.mfi_valor) && // Para contornar erro de mfi_valor_2 vindo preenchido mesmo com função de contexto
          !this.checkStringEmptyNull(filtro.mfi_valor_2))
      ) {
        if (filtro) {
          return filtro.con_titulo?.split("[")[0].replace(";", "");
        }
      }
      if (
        !this.checkStringEmptyNull(filtro.mfi_valor) &&
        !this.checkStringEmptyNull(filtro.mfi_valor_2)
      ) {
        return `${filtro.mfi_valor} - ${filtro.mfi_valor_2}`;
      }
      return filtro.mfi_valor;
    },
    checkStringEmptyNull(valor) {
      return valor == null || valor === "";
    }
  },
  mounted() {
    if (this.campanha.webhook) {
      this.campanha.webhook.url =
        Utils.getLinkWebhookCampanha(this.campanha) +
        "&destinatario=''&data_path=''";
    }
    ApiService.get(API_LINKS.campanha + "/" + this.campanha.modelo_campanha_id)
      .then((res) => {
        if (res.data.campanha.CALLBACK) {
          this.callbackDaCampanha = res.data.campanha.CALLBACK.cal_nome;
        }
        this.filtros = res.data.campanha.FILTROS;
        this.removeLastMfiEOu(this.filtros);
        this.modeloId = res.data.campanha.modelo_id;
        this.pegarModeloCampanha(this.modeloId);
      })
      .then(() => {
        ApiService.get(API_LINKS.campanha + "/filtro/" + this.modeloId).then(
          (res) => {
            this.camposPossiveisDoFiltro = res.data.filtros.map((filtro) => {
              return {
                fil_campo: filtro.fil_campo,
                fil_titulo: filtro.fil_titulo,
                filtro_id: filtro.filtro_id,
                ope_titulo: filtro.ope_titulo,
                fil_tipo_dado: filtro.fil_tipo_dado
              };
            });
          }
        );
      });
    ApiService.get(API_LINKS.campanha + "/operador").then((res) => {
      this.opreadoresFiltro = res.data.operadores.map((v) => {
        return {
          ope_titulo: v.ope_titulo,
          ope_sinal: v.ope_sinal,
          operador_id: v.operador_id
        };
      });
    });
    const init = async () => {
      const canais_rascunho = [];
      this.canaisDisponiveis = this.campanha.CANAIS.filter((canal) => {
        if (canal.cac_ordem != 0) {
          this.ordemCampanhaMensagens = "Sequencial";
        }
        if (!canal.cac_ativo && canal?.cac_mensagem?.length > 0) {
          canais_rascunho.push(canal);
          return false;
        }
        return canal.canal_id != 4;
      }).sort((a, b) => Number(a.cac_ordem) - Number(b.cac_ordem));
      this.canaisRascunho = canais_rascunho;
      this.mensagens.mensagemSelecionada = this.canaisDisponiveis[0];

      const response = await ApiService.get(
        API_LINKS.campanhaModelo + "/" + this.campanha.modelo_campanha_id
      );
      this.modelo = response.data.modelos[0];

      this.campanhaAtiva = this.campanha.CRONS.some(
        (v) => v.mdc_ativo === true
      );

      this.atualizarHistorico();

      // Subcontas
      const subcontas = await ApiService.get(API_LINKS.contasAtivas);

      this.subcontas = subcontas.data.subContas;
    };

    init();
  }
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;500;600&display=swap");

.texto-literal {
  background-color: #fcfafa;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  letter-spacing: 1.5px;
  font-size: 1rem;
}

.filtros-header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 16px;
}
.modal-detalhes-cabecacho {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}

.modal-detalhes-cabecacho-botao-voltar {
  width: 35px;
  height: 35px;
  margin-top: 2px;
  border: 2.5px solid #808080;
  color: #808080;
  border-radius: 50%;
}

.modal-detalhes-corpo {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.detalhes-collapse {
  outline: 1px solid #eee;
  padding: 0;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border-radius: 0.42rem;
}

.modal-detalhes-corpo-secao {
  display: flex;
  flex-direction: column;
  /* height: 75px; */
  width: 250px;
  margin-top: 1rem;
}

/* Texto menor */
.modal-detalhes-corpo-secao-descricao {
  display: block;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 0.9rem;
}

/* Texto maior */
.modal-detalhes-corpo-secao-informacao {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 1.33rem;
}
img {
  max-width: 100% !important;
}

.modal-detalhes-corpo-texto-campanha {
  width: 70%;
}

.modal-detalhes-corpo-status {
  display: block;
  margin-left: 20px;
}

.modal-detalhes-mensagem {
  width: 100%;
}

.camp-body-right-textarea {
  border: 1px solid #e4e6ef;
  border-top: none;
  background-color: #fff;
  padding: 20px;
  border-radius: 0px 7px 7px 7px;
  box-shadow: 0px 0px 5px rgb(245, 245, 245);
  min-height: 150px;
  padding-bottom: 20px;
  /* margin-bottom: 15px; */
}

.limita-caracteres {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.modal-detalhes-mensagem-textarea {
  background-color: #fff;
  border: 1px solid #a0a0a0;
  border-color: #e4e6ef #e4e6ef #e4e6ef;
  height: 200px;
  border-radius: 0px 0px 25px 25px;
  /* border: 1px solid #acacac; */
  padding: 20px;
  font-family: "Inter", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 300;
  font-size: 16px;
}

h6,
h5,
h4,
h3,
h2,
h1 {
  font-family: "Inter", sans-serif;
  font-weight: 300;
}

.block {
  display: block;
}

.rounded-top-rigth {
  border-radius: 10px 0px 0px 0px;
}

.nav-item > a,
.nav-item > a:hover,
.nav-link.active {
  /* border: 1px solid #a0a0a0; */
  border-bottom: none;
}

/* .nav {
  border: 1px solid #a0a0a0;
  border-top: none;
  border-right: none;
  border-left: none;
} */

#blockly1 {
  display: block;
  width: 100%;
  height: 390px;
}

.injectionDiv > svg {
  border-radius: 10px !important;
}

.blocklyFlyoutBackground {
  display: none !important;
}

.caixa-divisao {
  background-color: #ebedf3;
  padding: 15px;
  padding-top: 25px;
  border-radius: 25px;
  margin-top: 30px;
}

.camp-botao-voltar {
  color: #808080;
  transition: all 0.1s linear;
  cursor: pointer;
}

.camp-botao-voltar:hover {
  transform: scale(1.1);
}

.trash-container {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fa-trash:before {
  color: red;
}
.botao-filtro {
  width: 100%;
  padding: 2px 6px;
  border-radius: 5px;
  z-index: 10;
}
.camp-body {
  width: 100%;
  padding: 20px 20px;
  display: flex;
  flex-wrap: wrap;
}

.camp-body-left {
  width: 50%;
}

.camp-body-right {
  min-width: 50%;
  padding: 0px 30px;
}

.btn-executar-campanha {
  transition: 0s all;
  text-transform: uppercase;
  font-weight: bold;
  /* width: 200px; */
  padding: 12px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #007bff;
  /* background: #ffa800; */
  line-height: 0;
  color: #fff;
}
.executed {
  pointer-events: none;
  background: #454545 !important;
  border: none;
  cursor: not-allowed !important;
}

.back-arrow-squared {
  border: 1px solid #808080;
  border-radius: 4px;
  transform: rotate(-90deg);
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-icon.success {
  color: #18c5bd;
}

.menu-icon.primary {
  color: #3599fe;
}

.menu-icon.danger {
  color: #f44336;
}

.menu-icon.warning {
  color: #ffa800;
}
</style>
